import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import footer from '../../imagesfor/footer.jpg';
import logol1 from '../../imagesfor/logol1.png';
import logol from '../../imagesfor/logol.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const iconStyle = (iconName) => ({
    fontSize: 30,
    cursor: 'pointer',
    color: hoveredIcon === iconName ? 'darkorange' : '#ff6900',
    transition: 'color 0.3s ease'
  });

  const textStyle = (iconName) => ({
    color: hoveredIcon === iconName ? 'darkorange' : 'white',
    marginRight: '1rem',
    fontFamily: 'Readex Pro',
    cursor: 'pointer',
    transition: 'color 0.3s ease'
  });

  return (
    <Box id="footer"
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto', // Adjusted height for responsiveness
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centered content horizontally
        color: 'white',
        padding: '20px',
        direction: 'rtl'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${footer})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 34, 63, 0.7)',
          zIndex: -1,
        }}
      />
      <Grid container spacing={2} columns={{ xs: 12, md: 12 }} sx={{ maxWidth: '1200px' }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              sx={{
                marginTop: '1rem',
                fontSize: '24px', // Adjusted font size for smaller screens
                fontWeight: 'bold',
                lineHeight: '1',
                textAlign: 'center',
                textDecoration: 'underline',
                textDecorationColor: '#ff6900',
                fontFamily: 'Readex Pro',
                color: 'white',
              }}
            >
              تواصل معنا
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem', justifyContent: 'center' }}>
              <LocationOnIcon
                sx={iconStyle('location')}
                onMouseEnter={() => setHoveredIcon('location')}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              <Typography
                sx={textStyle('location')}
                onMouseEnter={() => setHoveredIcon('location')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                الرياض
              </Typography>
            </Box>
            <Link sx={{textDecoration:'none'}} to='tel:556663904 966+'>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', justifyContent: 'center' }}>
               
              <PhoneEnabledIcon
                sx={iconStyle('phone')}
                onMouseEnter={() => setHoveredIcon('phone')}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              <Typography
                sx={textStyle('phone')}
                onMouseEnter={() => setHoveredIcon('phone')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                556663904 966+
              </Typography>
              
            </Box>
</Link>
<Link sx={{textDecoration:'none'}} to='mailto:Arch.a.alosimi1@outlook.com'>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', justifyContent: 'center' }}>
               
              <EmailIcon
                sx={iconStyle('email')}
                onMouseEnter={() => setHoveredIcon('email')}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              <Typography
                sx={textStyle('email')}
                onMouseEnter={() => setHoveredIcon('email')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                Arch.a.alosimi1@outlook.com
              </Typography>
             
            </Box>
            </Link>
            <Link sx={{textDecoration:'none'}} to='https://api.whatsapp.com/send/?phone=966556663904&text&type=phone_number&app_absent'>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', justifyContent: 'center' }}>
               
              <FontAwesomeIcon
                style={iconStyle('whatsapp')}
                icon={faWhatsapp}
                onMouseEnter={() => setHoveredIcon('whatsapp')}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              <Typography
                sx={textStyle('whatsapp')}
                onMouseEnter={() => setHoveredIcon('whatsapp')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                +123 456 7890
              </Typography>
           
            </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <img src={logol1} alt="Logo" style={{ maxHeight: '100px', marginTop: '2rem', maxWidth: '80%' }} />
          <Typography
            sx={{
              fontWeight: 'bold',
              lineHeight: '1',
              textAlign: 'center',
              fontFamily: 'Readex Pro',
              color: 'white',
            }}
            variant='h6'
          >
            المهندس عبدالله الحمدان
          </Typography>
          <img src={logol} alt="Logo" style={{ maxHeight: '80px', marginTop: '1rem', maxWidth: '80%' }} />
        </Grid>
      
        
          <Typography
            sx={{
              color: 'white',
           marginRight:'5rem',
              marginTop: '1rem',
              fontFamily: 'Space Grotesk',
              marginLeft: { xs: '2rem', md: '0.5rem' }
              , textAlign:'center'
            }}
          >
</Typography>


<Typography
            sx={{
              color: 'white',
           marginRight:{xs:'0rem',sm:'17rem'},
              marginTop: '1rem',
              fontFamily: 'Space Grotesk',
              marginLeft: { xs: '2rem', md: '0.5rem' }
              , textAlign:'center'
            }}
          > 
         
         </Typography>
      </Grid>
      
   
    </Box>

  );
};

export default Footer;
